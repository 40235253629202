import { useTranslation } from 'react-i18next';
import {
	CurrencyChev,
	F2XMainIcon,
	HiddenBalanceIcon,
	PositiveChev,
	SearchIcon
} from '../common/icons';
import { MainNavigationContainer } from '../common/navigation';
import { ROUTES } from '../../types/routes';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useState } from 'react';

const WalletLayout = () => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const [isBalanceHidden, setIsBalanceHidden] = useState<boolean>(false);

	const num = 54087.92;

	return (
		<div className='walletLayout'>
			<div onClick={() => setIsBalanceHidden(!isBalanceHidden)} className='estimatedBalanceContainer'>
				<h1 className='estimatedBalanceText'>
					{t('estimatedBalanceText')}
				</h1>
				<HiddenBalanceIcon isHidden={isBalanceHidden} className='estimatedBalanceIcon' />
			</div>
			<div className='balanceContainer'>
				<div className='mainBalanceContainer'>
					<h1 className='mainBalanceText'>{isBalanceHidden ? "***,**" : num.toLocaleString()}</h1>
					<div className='mainBalanceSelectContainer'>
						<h1 className='currencyText'>USD</h1>
						<CurrencyChev className='currencyTextChev' />
					</div>
				</div>
				<div className='secondaryBalanceContainer'>
					<h1 className='secondaryBalanceText'>{t('todayText')}</h1>
					<div className='secondaryBalanceAnalyticsContainer'>
						<PositiveChev className='analyticsChev' />
						<h1 className='secondaryBalanceAnalyticsText'>
							15,12% (+534,11 USD)
						</h1>
					</div>
				</div>
			</div>
			<MainNavigationContainer />
			<F2XMainIcon />

			<div className='hideBalanceContainer'>
				<div className='innerHideBalanceContainer'>
					<h1 className='hideBalanceText'>
						{t('hideBalanceText')}
						<a onClick={() => navigate(ROUTES.EXCHANGE.INDEX)} className='hideBalanceLinkText'>
							{t('convertText')}
						</a>
					</h1>
				</div>
				<SearchIcon className='searchIconContainer' />
			</div>

			<div className='assetsContainer'>
				<div className='assetItem'>

				</div>
			</div>
		</div>
	);
};

export default WalletLayout;
