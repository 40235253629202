import { createBrowserRouter } from 'react-router-dom';
import { ROUTES } from './types/routes';
import Root from './routes/root.route';
import ErrorLayout from './components/error';
import Exchange from './routes/exchange.route';
import Market from './routes/market.route';

export const router = createBrowserRouter([
	{
		path: ROUTES.ROOT.INDEX,
		element: <Root />,
		errorElement: <ErrorLayout />
	},
	{
		path: ROUTES.WALLET.INDEX,
		element: <Root />,
		errorElement: <ErrorLayout />
	},
	{
		path: ROUTES.EXCHANGE.INDEX,
		element: <Exchange />,
		errorElement: <ErrorLayout />
	},
	{
		path: ROUTES.MARKET.INDEX,
		element: <Market />,
		errorElement: <ErrorLayout />
	}
]);
