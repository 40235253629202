import HeaderLayout from "../common/header";

const MarketLayout = () => {

	return (
		<>
			<div>
				<div className={"component"}>
					<div className={"innerComponent"}>
						<HeaderLayout/>
					</div>
				</div>
			</div>
		</>
	);
};

export default MarketLayout;
