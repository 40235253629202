import { useTranslation } from 'react-i18next';
import { AccountIcon, F2XIcon, HistoryIcon } from '../icons';
import { useLocation } from 'react-router-dom';

const HeaderLayout = () => {
	const { t } = useTranslation();

	const location = useLocation();

	return (
		<div className='headerLayout'>
			<div className='headerLogoContainer'>
				<F2XIcon className='f2xIcon' />
				<h1 className='headerMainText'>
					{t(location.pathname.split('/')[2])}
				</h1>
			</div>
			<div className='headerNavContainer'>
				<HistoryIcon className='headerNavIcon' />
				<AccountIcon className='headerNavIcon' />
			</div>
		</div>
	);
};

export default HeaderLayout;
