import HeaderLayout from '../common/header';
import WalletLayout from '../wallet';

const RootLayout = () => {
	return (
		<>
			<div>
				<div className={'component'}>
					<div className={'innerComponent'}>
						<HeaderLayout />
						<WalletLayout />
					</div>
				</div>
			</div>
		</>
	);
};

export default RootLayout;
