import { useTranslation } from 'react-i18next';
import { DepositIcon, ExchangeIcon, MarketIcon, WithdrawIcon } from '../icons';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';

const MainNavigationContainer = () => {
	const { t } = useTranslation();

	const navigate = useCustomNavigate();

	return (
		<div className='mainNavigationContainer'>
			<div className='navigationItem'>
				<DepositIcon className='navigationIcon' />
				<h1 className='navigationText'>{t('deposit')}</h1>
			</div>
			<div
				onClick={() => navigate(ROUTES.EXCHANGE.INDEX)}
				className='navigationItem'
			>
				<ExchangeIcon className='navigationIcon' />
				<h1 className='navigationText'>{t('exchange')}</h1>
			</div>
			<div className='navigationItem'>
				<WithdrawIcon className='navigationIcon' />
				<h1 className='navigationText'>{t('withdraw')}</h1>
			</div>
			<div onClick={() => navigate(ROUTES.MARKET.INDEX)} className='navigationItem'>
				<MarketIcon className='navigationIcon' />
				<h1 className='navigationText'>{t('market')}</h1>
			</div>
		</div>
	);
};

export { MainNavigationContainer };
