export const TITLES = {
	ROOT: {
		INDEX: "Wallet"
	},
	EXCHANGE: {
		INDEX: "Exchange"
	},
	MARKET: {
		INDEX: "Market"
	}
};
