import { Helmet } from 'react-helmet';
import { TITLES } from '../types/titles';
import MarketLayout from '../components/market';

const Market = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.MARKET.INDEX}</title>
			</Helmet>
			<MarketLayout />
		</>
	);
};

export default Market;
